.navbar .logo {
    width: 20px;
}

.navbar .d-flex {
	align-items: center;
}

.navbar-light .navbar-nav .nav-link {
    color: $light;
}

.navbar .current-menu {
    font-weight: 700;
}

// Bouton de changement de thème clair/sombre
.nav-link.theme {
    cursor: pointer;
}

/**
 * Menus déroulants de la toolbar : évite un message bizarre de Popper.js dans
 * la console, cf. https://github.com/react-bootstrap/react-bootstrap/issues/5081
 */
ul.navbar-nav.flex-row.flex-wrap > li > ul.dropdown-menu {
    margin: 0;
}

// Menu déroulmant de la toolbar pour les langues
#locale.dropdown-menu {
    // On enlève la largeur minimale sinon le libellé est très cours et le menu large
    min-width: unset;
}

// Les ancres dsont placées un peu plus haut dans le cas où la barre de navigation est flottante
/*.anchor {
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;
}*/

