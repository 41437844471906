$secondary-light: #cccdd1;
$background-light: white;

body.light {
	/***************************************************************************
	 * GENERALITÉS
	 **************************************************************************/
	// Tout le document
	color: var(--bs-body-color);
	background-color: $secondary-light;

	// La form prend quasi tout le body
	& > form {
		background-color: $background-light;
	}

	// Barres de boutons en haut et en bas
	& .buttonbar {
		&.top {
			background-color: $secondary-light;
		}

		&.bottom {
			background-color: $background-light;
		}
	}

	// Zone entre la toolbar et le footer
	& > .body {
		background-color: $background-light;

		// Toutes les images
		& img {
		    border: solid 1px #212529;
		}
	}

	// Couleur de fond secondaire
	& .bg-fej-secondary {
		background-color: $secondary-light;
	}

	& .bg-fej-secondary .nav-link {
		color: rgb(33, 37, 41);
	}
}
