footer {
    & .logo {
        width: 48px;
    }

    // Les liens dans le footer ne sont pas mis en évidence
    & a.nav-link {
        color: inherit!important;
    }

    // Les textes ne sont pas justifiés dans le footer
    & p, li {
        text-align: inherit;
    }
}
