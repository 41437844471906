div.story {
    & + * {
        clear: both;
    }

    & h3 {
        margin-left: 1em;
    }

    & h4 {
        margin-left: 2em;
    }

    & h5 {
        margin-left: 3em;
    }

    & h6 {
        margin-left: 4em;
    }
}

div.chapter-image {
    margin: 1em;
}

div.chapter-image.left {
    clear: left;
    float: left;
}

div.chapter-image.center {
    clear: both;
    text-align: center;
    text-align: -webkit-center;

    & img {
        //border: none;
        max-width: 100%;
    }
}

div.chapter-image.right {
    clear: right;
    float: right;
}
