/***************************************************************************
 * TOPBAR ET BOTTOMBAR
 **************************************************************************/
// Gestion des barres fixes en haut de page pour le scrolling
html {
    // Par défaut tous les objets : sticky bar 56px - potentielle bordure de l'item au dessus 1px
    scroll-padding-top: 55px;

    // On peut donner un peu d'air par rapport au sommet de l'objet s'il a une marge supérieure (+20)
    & .entity {
        scroll-margin-top: 20px;
    }

    // Complément quand il y a une seconde barre : sticky bar 56px + topbar_buttons 51px - potentielle bordure de l'item au dessus 1px
    &.additional-toolbar {
        scroll-padding-top: 106px;
    }
}

.buttonbar {
    // Alignement vertical des boutons avec les simples liens
    & .container-lg {
        align-items: baseline;
    }

    // Boutons
    & a.btn, button {
        margin: 0 0.1em;
        vertical-align: baseline;
    }

    // Si la barre de bouton contient des boutons de formulaire, ils sont encapsulés dans un div pourri
    & div div {
        display: inline-block;
    }

    // Autres trucs qui nécessitent de ne pas avoir de marge verticale
    & * {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
}

/***************************************************************************
 * PANNEAUX DE CONTRÔLE LATÉRAUX
 **************************************************************************/
.entity {
    position: relative;
    border-left: 5px solid #808080;
    //box-shadow: 0px 0px 20px 0px rgba(128, 128, 128, 0.20);//0px 0px 32px -14px rgba(0, 0, 0, 0.22);
    //background: linear-gradient(180deg, rgba(128, 128, 128, 0.25) 0px, rgba(128, 128, 128, 0) 100px);
    //border-radius: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    & > div.control {
        position: absolute;
        width: unset;
        right: 0;
    }
}

/***************************************************************************
 * FORMULAIRES
 **************************************************************************/
 form  {
    & fieldset {
        // On annule une spécificité qui fait qu'un label de ChoiceType est en fait un legend, avec un padding-top absent des labels, qui désorganise la page
        & > legend {
            padding-top: 0px !important;
        }

        // On fait en sorte que les EntityTypes de type checkbox s'affichent sur plusieurs colonnes
        & .form-check {
            display: inline-block;
            width: 259px;
        }
    }

    & ol.entities {
        list-style-type: none;
        padding-left: 0px;

        & > li.entity {
            padding: 1em 0px 1em 1em;
            margin: 1em 0px;
        
            // Astuce pour ajouter un espace avant le div qui suit le div.control
            & > div.control + * {
                margin-top: 1em;
            }
        }

        & > li.entity:last-child > div.control > a.down, > li.entity:first-child > div.control > a.up {
            // On cache le premier bouton up et le dernier bouton down dans une liste d'entités ordonnées
            display: none;
        }
    }    
}


/***************************************************************************
 * ACCORDEONS BOOTSTRAP
 **************************************************************************/
.accordion-button:focus {
    box-shadow: unset;
}

.accordion-button:not(.collapsed) {
    background-color: unset;
}

button.accordion-button:focus:not(:focus-visible) {
    outline: none;
}

/***************************************************************************
 * PAGINATION
 **************************************************************************/
ul.pagination > li.page-item:not(.active) > .page-link {
    background-color: transparent;
}

// Liens désactivés (ex : "Précédent" quand on est sur le page 1) s'adaptent au thème automatiquement
.pagination > li.page-item.disabled > .page-link {
    color: inherit;
}

.page-link.active, .active > .page-link {
    border-color: unset;
}

/***************************************************************************
 * GENERALITÉS
 **************************************************************************/
// Titres
h1 {
    padding-top: 2rem;
}

h2 {
    padding-top: 1rem;
}

// Exception accordéons
div.accordion h2 {
    padding-top: 0px;
}

// Paragraphes
p, li {
    text-align: justify;
}

// Boutons dans les tableaux (a.btn comme button.btn)
table .btn {
    vertical-align: baseline;
}

table.table-equal-columns {
    table-layout: fixed;
}

// Boutons à taille rikiki
.btn-xs, .btn-group-xs > .btn {
    --bs-btn-padding-y: 0.1rem;
    --bs-btn-padding-x: 0.3rem;
    --bs-btn-font-size: 0.6rem;
    --bs-btn-border-radius: 0.25rem;
    vertical-align: text-bottom;
}

// Photos
figure {
    display: table;
    margin: 0px;
}

.dojo-logo {
    width: 100px;
}

// Légende des photos
figure > figcaption {
    display: table-caption;
    caption-side: bottom ;
    text-align: center;
    font-size: 75%;
}

// On écrase les couleurs imposées par Bootstrap afin de permettre notre mode jour/nuit
.list-group-item {
    color: unset;
    background-color: unset;
}

// Cartes
.card figure {
    margin-bottom: 0px;
}

.card-text {
    text-align: center;
}

// Blocs citation
blockquote {
    border-left: 5px solid #ccc;
    padding-left: 1.5em;
    font-style: italic;
}

// Utilitaire pour mettre une bordure épaisse
.border-left {
    border-left: 5px solid #808080;
    margin-left: 50px;
    margin-bottom: 50px;
}

// Utilitaire pour éviter de séparer des mots par un saut de ligne
.unbreakable {
    white-space: nowrap;
}

// Utilitaire pour cacher un truc
.hidden {
    display: none;
}

// Utilitaire pour rendre des trucs petits
.small {
    font-size: 75%;
}

// Utilitaire pour enlever les effets de texte
.normal {
    font-weight: 400;
}

// Utilitaire pour rendre des trucs ténus
.dim {
    opacity: 50%;
}
