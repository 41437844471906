$secondary-dark: #383838;
$background-dark: var(--bs-body-color);
$background-dark-info: #0c3054;
$background-dark-success: #065632;
$background-dark-warning: #5e4f1f;
$background-dark-danger: #680101;
$border-dark-info: #2b8395;
$border-dark-success: #266c4d;
$border-dark-warning: #2b8395;
$border-dark-danger: #9b353f;

body.dark {
	/***************************************************************************
	 * GENERALITÉS
	 **************************************************************************/
	// Tout le document
	color: white;
	background-color: $secondary-dark;

	// Liens
	& a:link {
		color: #b3daff;
	}

	& a:visited {
		color: #60a2db
	}

	& a:hover {
		color: #80c1ff;
	}

	// La form prend quasi tout le body
	& > form {
		background-color: $background-dark;
	}

	// Barres de boutons en haut et en bas
	& .buttonbar {
		&.top {
			background-color: $secondary-dark;
		}

		&.bottom {
			background-color: $background-dark;
		}
	}

	// zone entre la toolbar et le footer
	& > .body {
		background-color: $background-dark;

		// Toutes les images
		& img {
		    border: solid 1px grey;
		}

		// Cards
		& .card {
			border-color: grey;
			background-color: inherit;
		}
	}

	& a.dropdown-item {
		color: white;
	}

	& .dropdown-menu.dropdown-menu-end.show {
		background-color: $secondary-dark;
	}

	& .dropdown-item:hover, .dropdown-item:focus {
		background-color: #444444;
	}

	// Couleur de fond secondaire
	& .bg-fej-secondary {
		background-color: $secondary-dark;
	}

	/***************************************************************************
	 * BOUTONS BOOTSTRAP
	 **************************************************************************/
	// Boutons
	& .btn {
    	color: #ffffff !important;
    	--bs-btn-color: #ffffff !important;
	}

	/***************************************************************************
	 * TABLES BOOTSTRAP
	 **************************************************************************/
	// Toute la table
 	& .table {
 		//color: white;
		--bs-table-color-state: white;
		--bs-table-bg: #{$background-dark};

		// Lignes colorées
		& .table-info {
			--bs-table-bg: #{$background-dark-info};
		}

		& .table-success {
			--bs-table-bg: #{$background-dark-success};
		}

		& .table-warning {
			--bs-table-bg: #{$background-dark-warning};
		}

		& .table-danger {
			--bs-table-bg: #{$background-dark-danger};
		}

		// Liens
		& a:link {
			color: #dddddd;
		}

		& a:visited {
			color: #eeeeee;
		}

		& a:hover {
			color: #d1e7dd;
		}
	}

	/***************************************************************************
	 * ALERTES BOOTSTRAP
	 **************************************************************************/
	& .alert {
		&.alert-info {
			color: white;
			--bs-alert-bg: #{$background-dark-info};
			--bs-alert-border-color: #{$border-dark-info};
		}

		&.alert-success {
			color: white;
			--bs-alert-bg: #{$background-dark-success};
			--bs-alert-border-color: #{$border-dark-success};
		}

		&.alert-warning {
			color: white;
			--bs-alert-bg: #{$background-dark-warning};
			--bs-alert-border-color: #{$border-dark-warning};
		}

		&.alert-danger {
			color: white;
			--bs-alert-bg: #{$background-dark-danger};
			--bs-alert-border-color: #{$border-dark-danger};
		}

		// Icone en croix pour fermer l'alerte
		& .btn-close {
			background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>");
		}

		// Liens
		& a:link {
			color: #dddddd;
		}

		& a:visited {
			color: #eeeeee;
		}

		& a:hover {
			color: white;
		}
	}

	/***************************************************************************
	 * ACCORDEONS BOOTSTRAP
	 **************************************************************************/
	& .accordion-item, .accordion-button {
		color: white;
		background-color: $secondary-dark;
	}

	& .accordion-button::after {
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
	}

	/***************************************************************************
	 * MODALS BOOTSTRAP
	 **************************************************************************/
	& .modal-content {
		background-color: $secondary-dark;
	}

	// Icone en croix pour fermer la modal
	& .modal .btn-close {
		background: transparent url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>") center/1em auto no-repeat;
	}

	/***************************************************************************
	 * CK EDITOR
	 **************************************************************************/
	// Zone de texte
	& .ck.ck-content.ck-editor__editable {
		color: white;
    	background-color: $secondary-dark;
	}

	// Toolbar
	& .ck.ck-toolbar {
		background-color: $secondary-dark;
	}

	// Boutons de la toolbar (textuels et/ou icones SVG)
	& .ck.ck-button, a.ck.ck-button {
		color: white;
	}

	// Boutons de la toolbar actifs (textuels et/ou icones SVG)
	& .ck.ck-button.ck-on, a.ck.ck-button.ck-on {
		background-color: #444444;
	}

	// Boutons de la toolbar survolés (sauf les boutons désactivés)
	& .ck.ck-button:not(.ck-disabled):hover {
		background-color: #555555;
	}

	// Liste déroulante de la toolbar (exemple : styles), bouton une fois déplié, non survolé
	& .ck.ck-button.ck-on.ck-dropdown__button:not(.ck-disabled), a.ck.ck-button.ck-on.ck-dropdown__button:not(.ck-disabled) {
		background-color: #444444;
	}

	// Liste déroulante de la toolbar (exemple : styles), bouton une fois déplié, survolé
	& .ck.ck-button.ck-on.ck-dropdown__button:not(.ck-disabled):hover, a.ck.ck-button.ck-on.ck-dropdown__button:not(.ck-disabled):hover {
		background-color: #555555;
	}

	// Liste déroulante de la toolbar (exemple : styles), zone déroulée
	& .ck.ck-reset.ck-list {
		color: white;
		background-color: $secondary-dark;
	}

	/***************************************************************************
	 * FORMULAIRES STANDARD
	 **************************************************************************/
	// Contrôle actif
	& .form-control, .form-select {
		color: white;
		background-color: $secondary-dark;
	}

	// Labels des input groups
	& .input-group-text {
		color: unset;
		background-color: #070605;
	}

	// Flèche des listes déroulantes
	& .form-select {
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
	}

	// Contrôle inactif
	& .form-control:disabled, .form-select:disabled {
		color: #bbbbbb;
		background-color: #777777;
	}

	// Icône webkit du calendrier (datetime picker)
	& input[type="date" i]::-webkit-calendar-picker-indicator {
	    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJ3aGl0ZSIgZD0iTTIwIDNoLTFWMWgtMnYySDdWMUg1djJINGMtMS4xIDAtMiAuOS0yIDJ2MTZjMCAxLjEuOSAyIDIgMmgxNmMxLjEgMCAyLS45IDItMlY1YzAtMS4xLS45LTItMi0yem0wIDE4SDRWOGgxNnYxM3oiLz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDB6Ii8+PC9zdmc+);
	}

	// Placeholders
	& input::placeholder {
		color: #bbbbbb;
	}
	
	// Messages d'aide
	& .form-text {
		color: #bbbbbb;
	}
}
